var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "bg-white" },
    [
      _c(
        "v-container",
        { staticClass: "position-sticky top-0 z-index-2" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("app-bar-blur-auth")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "auth-pages" },
        [
          _c(
            "div",
            {
              staticClass:
                "header-auth position-relative pb-16 pt-16 border-radius-xl min-vh-75",
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column mx-auto",
                          attrs: { lg: "4", md: "6", cols: "12" },
                        },
                        [
                          _c(
                            "fade-transition",
                            {
                              attrs: {
                                duration: 200,
                                origin: "center top",
                                mode: "out-in",
                              },
                            },
                            [_c("router-view")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { md: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "oblique position-absolute top-0 h-100 d-md-block d-none me-n8",
                          },
                          [
                            _c("div", {
                              staticClass:
                                "oblique-image position-absolute fixed-top ms-auto h-100 w-100 z-index-0 ms-n16",
                              style: `background-image: url(${require("../../assets/img/curved-images/curved11.jpg")}`,
                            }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.$route.meta.hideFooter
            ? _c("content-footer", { attrs: { auth: "" } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }