var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "py-1", attrs: { cols: "6" } }, [
            _c(
              "div",
              {
                staticClass:
                  "py-12 h-100 w-100 d-flex border-radius-lg position-relative dropdown-image",
                style: `background-image: url(${require("../../assets/img/curved-images/curved8.jpg")}); background-size: cover;`,
              },
              [
                _c("div", {
                  staticClass:
                    "mask bg-gradient-primary border-radius-lg opacity-8",
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-center text-center text-white font-weight-bold w-100 z-index-1 flex-column",
                  },
                  [
                    _c(
                      "v-avatar",
                      {
                        staticClass: "shadow",
                        attrs: {
                          color: "bg-white",
                          width: "48",
                          height: "48",
                          "min-width": "48",
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "text-primary text-gradient",
                            attrs: { size: "20" },
                          },
                          [_vm._v(" ni ni-diamond ")]
                        ),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "text-lg mt-2" }, [
                      _vm._v("Explore our"),
                      _c("br"),
                      _vm._v("utilities pages"),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            {
              staticClass: "ps-0 d-flex justify-content-center flex-column",
              attrs: { cols: "6" },
            },
            [
              _c(
                "v-list",
                { staticClass: "pt-0" },
                _vm._l(_vm.pages, function (item) {
                  return _c(
                    "v-list-item",
                    { key: item.title, staticClass: "mb-0 ps-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-body text-capitalize ls-0",
                          attrs: {
                            ripple: false,
                            text: "",
                            depressed: "",
                            link: "",
                            to: item.link,
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(item.title) + " "),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }