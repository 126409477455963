var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "virtual-reality" },
    [
      _c(
        "div",
        [
          _c("app-bar", {
            attrs: {
              background: "bg-transparent",
              "has-bg": "",
              "toggle-active": _vm.drawer,
            },
            on: {
              "drawer-toggle": function ($event) {
                _vm.drawer = $event
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-main",
        {
          staticClass: "mt-4 mx-4 border-radius-xl position-relative",
          style: `background-image: url(${require("../../assets/img/vr-bg.jpg")}); background-size: cover;`,
        },
        [
          _c("drawer", {
            staticClass: "bg-white border-radius-xl",
            attrs: { drawer: _vm.drawer },
          }),
          _c(
            "fade-transition",
            { attrs: { duration: 200, origin: "center top", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      !_vm.$route.meta.hideFooter ? _c("content-footer") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }