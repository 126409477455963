var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "my-4 ms-4 border-radius-lg",
      class: !_vm.$vuetify.breakpoint.mobile ? "" : "bg-white",
      attrs: {
        width: "100%",
        fixed: "",
        app: "",
        floating: "",
        "expand-on-hover": _vm.mini,
        value: _vm.drawer,
        right: _vm.$vuetify.rtl,
        "data-color": _vm.sidebarColor,
        "data-theme": _vm.sidebarTheme,
      },
    },
    [
      _c(
        "v-list-item",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list-item-content",
            { staticClass: "pa-0" },
            [
              _c(
                "v-list-item-title",
                { staticClass: "title d-flex align-center mb-0" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "v-navigation-drawer-brand pa-5 align-center mt-10",
                    },
                    [
                      _c("v-img", {
                        staticClass: "navbar-brand-img ms-3",
                        attrs: {
                          src: require("@/assets/img/logo--seo-team-flow.png"),
                          width: "190",
                        },
                      }),
                      _c("br"),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr", { staticClass: "horizontal light mb-4" }),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _vm._l(_vm.items, function (item) {
            return _c(
              "v-list-group",
              {
                key: item.title,
                staticClass: "pb-1 mx-2",
                attrs: {
                  ripple: false,
                  "append-icon": "fas fa-angle-down",
                  "active-class": "item-active",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "shadow border-radius-md mx-2 align-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  domProps: { innerHTML: _vm._s(item.action) },
                                },
                                [_vm._v(" " + _vm._s(item.action) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                staticClass: "ms-1",
                                domProps: { textContent: _vm._s(item.title) },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: item.active,
                  callback: function ($$v) {
                    _vm.$set(item, "active", $$v)
                  },
                  expression: "item.active",
                },
              },
              _vm._l(item.items, function (child) {
                return _c(
                  "v-list-item",
                  {
                    key: child.title,
                    staticClass: "mb-0 no-default-hover",
                    attrs: { ripple: false, link: "", to: child.link },
                  },
                  [
                    _c("span", {
                      staticClass: "v-list-item-mini",
                      domProps: { textContent: _vm._s(child.prefix) },
                    }),
                    !child.items
                      ? _c(
                          "v-list-item-content",
                          { staticClass: "ms-6 ps-7" },
                          [
                            _c("v-list-item-title", {
                              domProps: { textContent: _vm._s(child.title) },
                              on: {
                                click: function ($event) {
                                  return _vm.listClose($event)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    child.items
                      ? _c(
                          "v-list-item-content",
                          { staticClass: "ms-6 ps-7 py-0" },
                          [
                            _c(
                              "v-list-group",
                              {
                                attrs: {
                                  "prepend-icon": "",
                                  ripple: false,
                                  "sub-group": "",
                                  "no-action": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "v-list-item-mini" },
                                            [_vm._v(_vm._s(child.prefix))]
                                          ),
                                          _c(
                                            "v-list",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { nav: "", dense: "" },
                                            },
                                            [
                                              _c("v-list-group", {
                                                staticClass: "mb-0",
                                                attrs: {
                                                  ripple: false,
                                                  "append-icon":
                                                    "fas fa-angle-down me-auto ms-1",
                                                  "active-class": "item-active",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        child.title
                                                                      ),
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: child.active,
                                  callback: function ($$v) {
                                    _vm.$set(child, "active", $$v)
                                  },
                                  expression: "child.active",
                                },
                              },
                              _vm._l(child.items, function (child2) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: child2.title,
                                    attrs: { ripple: false, to: child2.link },
                                    on: {
                                      click: function ($event) {
                                        return _vm.listClose($event)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "v-list-item-mini",
                                      domProps: {
                                        textContent: _vm._s(child2.prefix),
                                      },
                                    }),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          domProps: {
                                            textContent: _vm._s(child2.title),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              1
            )
          }),
          _c(
            "h5",
            {
              staticClass:
                "text-uppercase text-caption ls-0 font-weight-bolder p-0 text-muted mx-4 mt-4 mb-2 ps-2 d-none-mini white-space-nowrap",
            },
            [_vm._v(" Pages ")]
          ),
          _vm._l(_vm.itemsPages, function (item) {
            return _c(
              "v-list-group",
              {
                key: item.title,
                staticClass: "pb-1 mx-2",
                attrs: {
                  ripple: false,
                  "append-icon": "fas fa-angle-down",
                  "active-class": "item-active",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "shadow border-radius-md mx-2 align-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  domProps: { innerHTML: _vm._s(item.action) },
                                },
                                [_vm._v(" " + _vm._s(item.action) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                staticClass: "ms-1",
                                domProps: { textContent: _vm._s(item.title) },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: item.active,
                  callback: function ($$v) {
                    _vm.$set(item, "active", $$v)
                  },
                  expression: "item.active",
                },
              },
              _vm._l(item.items, function (child) {
                return _c(
                  "v-list-item",
                  {
                    key: child.title,
                    staticClass: "mb-0 no-default-hover",
                    class: child.active ? "item-active" : "",
                    attrs: { ripple: false, link: "", to: child.link },
                  },
                  [
                    _c("span", {
                      staticClass: "v-list-item-mini",
                      domProps: { textContent: _vm._s(child.prefix) },
                    }),
                    !child.items
                      ? _c(
                          "v-list-item-content",
                          { staticClass: "ms-6 ps-7" },
                          [
                            _c("v-list-item-title", {
                              domProps: { textContent: _vm._s(child.title) },
                              on: {
                                click: function ($event) {
                                  return _vm.listClose($event)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    child.items
                      ? _c(
                          "v-list-item-content",
                          { staticClass: "ms-6 ps-7 py-0" },
                          [
                            _c(
                              "v-list-group",
                              {
                                attrs: {
                                  "prepend-icon": "",
                                  ripple: false,
                                  "sub-group": "",
                                  "no-action": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "v-list-item-mini" },
                                            [_vm._v(_vm._s(child.prefix))]
                                          ),
                                          _c(
                                            "v-list",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { nav: "", dense: "" },
                                            },
                                            [
                                              _c("v-list-group", {
                                                staticClass: "mb-0",
                                                attrs: {
                                                  ripple: false,
                                                  "append-icon":
                                                    "fas fa-angle-down me-auto ms-1",
                                                  "active-class": "item-active",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        child.title
                                                                      ),
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: child.active,
                                  callback: function ($$v) {
                                    _vm.$set(child, "active", $$v)
                                  },
                                  expression: "child.active",
                                },
                              },
                              _vm._l(child.items, function (child2) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: child2.title,
                                    attrs: { ripple: false, to: child2.link },
                                    on: {
                                      click: function ($event) {
                                        return _vm.listClose($event)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("span", {
                                          staticClass: "v-list-item-mini",
                                          domProps: {
                                            textContent: _vm._s(child2.prefix),
                                          },
                                        }),
                                        _c("v-list-item-title", {
                                          domProps: {
                                            textContent: _vm._s(child2.title),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              1
            )
          }),
          _c("hr", { staticClass: "horizontal dark my-4" }),
          _c(
            "h5",
            {
              staticClass:
                "text-uppercase text-caption ls-0 font-weight-bolder p-0 text-muted mx-4 mt-4 mb-2 ps-2 d-none-mini white-space-nowrap",
            },
            [_vm._v(" Docs ")]
          ),
          _c(
            "v-list-item-group",
            _vm._l(_vm.itemsDocs, function (item, i) {
              return _c(
                "div",
                { key: i },
                [
                  !item.external
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "pb-1 mx-2 no-default-hover",
                          attrs: {
                            link: "",
                            to: item.link,
                            ripple: false,
                            "active-class": "item-active",
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "shadow border-radius-md mx-2 align-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  domProps: { innerHTML: _vm._s(item.action) },
                                },
                                [_vm._v(" " + _vm._s(item.action) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                staticClass: "ms-1",
                                domProps: { textContent: _vm._s(item.title) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.external
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "pb-1 mx-2 no-default-hover",
                          attrs: {
                            link: "",
                            href: item.link,
                            ripple: false,
                            "active-class": "item-active",
                            target: "_blank",
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "shadow border-radius-md mx-2 align-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  domProps: { innerHTML: _vm._s(item.action) },
                                },
                                [_vm._v(" " + _vm._s(item.action) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                staticClass: "ms-1",
                                domProps: { textContent: _vm._s(item.title) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }