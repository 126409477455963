<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="header-auth position-relative ma-4 pb-16 pt-16 border-radius-xl"
        :style="`background-image:  url(${require('../../assets/img/curved-images/curved6.jpg')}; background-size: cover; background-position: 50%;`"
      >
        <span
          class="mask bg-gradient-default border-radius-xl opacity-6"
        ></span>
        <v-container>
          <v-row class="d-flex mt-5">
            <v-col cols="12" md="6" class="mx-auto py-0 position-relative">
              <h1
                class="
                  text-h1 text-white
                  font-weight-bolder
                  text-center
                  mb-2
                  mt-5
                "
              >
                {{ headerTitle() }}
              </h1>
              <p
                class="
                  text-white
                  font-size-root
                  text-center
                  font-weight-thin
                  mb-12
                "
              >
<!--                {{ paragraphs }}-->
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
<!--      <app-bar-auth background="transparent" has-bg linkColor="white">-->
<!--      </app-bar-auth>-->
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container class="mt-n16 pb-0">
          <router-view></router-view>
          <content-footer auth v-if="!$route.meta.hideFooter"></content-footer>
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
    };
  },
  methods: {
    headerTitle() {
      switch (this.$route.name) {
        case "LoginIndex":
          this.paragraphs = "";
          return "Welcome Back!";
        default:
          break;
      }
    },
  },
};
</script>
