var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { staticClass: "px-3" },
    _vm._l(_vm.pages, function (item) {
      return _c(
        "v-list-item",
        { key: item.title, staticClass: "mb-0 ps-0" },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-body text-capitalize ls-0",
              attrs: {
                ripple: false,
                text: "",
                depressed: "",
                link: "",
                to: item.link,
              },
            },
            [
              _c("v-icon", { attrs: { size: "16" } }, [
                _vm._v(
                  " " + _vm._s(item.icon) + " text-primary text-gradient me-3 "
                ),
              ]),
              _vm._v(" " + _vm._s(item.title) + " "),
              _c("v-spacer"),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }