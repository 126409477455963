import mainState from '@/mixins/mainState.js'
import mainApi from '@/apis/domains/eepseo.com/mainApi.js'
import * as SecureLS from 'secure-ls'
const APP_KEY = process.env.VUE_APP_KEY

export default {

  mixins: [mainState, mainApi],

  data () {
    return {

    }
  },

  watch: {

  },

  computed: {

  },

  created () {
    this.setIpAddress()
  },

  updated () {

  },

  mounted () {

  },

  methods: {

    _isEmpty(obj) {
      return Object.keys(obj).length === 0
    },

    _isMobile () {
      // return $vuetify.breakpoint.mobile
    },

    setIpAddress() {
      const clientIpAddress = this.mainStateGet('clientIpAddress')
      if (clientIpAddress === '') this.apiGetIpAddress()
    },

    setClientListOptions() {
      const clientDataList = this.mainStateGet('clientData')
      console.log('clientData encrypted', this.getSessionVal('clientData'))
      const clientDataListOptions = this.getSessionVal('clientData', true) // this.mainStateGet('clientDataListOptions')
      console.log('clientDataListOptions', clientDataListOptions)
      let tempOptions = []
      if (this._isEmpty(clientDataListOptions)) {
        clientDataList.forEach(function(x) {
          tempOptions.push({ id: x.id, name: x.name })
        })
        this.mainStateSet('clientDataListOptions', tempOptions)
      }
    },

    updateClientListOptions () {
      const checkClientDataList = this.mainStateGet('clientData')
      let tempOptions = []
      checkClientDataList.forEach(function(x) {
        tempOptions.push({ id: x.id, name: x.name })
      })
      this.mainStateSet('clientDataListOptions', tempOptions)
    },

    setProjectListOptions() {
      const projectDataList = this.mainStateGet('projectData')
      const projectDataListOptions = this.mainStateGet('projectDataListOptions')
      let tempOptions = []
      if (this._isEmpty(projectDataListOptions)) {
        projectDataList.forEach(function(x) {
          tempOptions.push({ id: x.id, name: x.name })
        })
        this.mainStateSet('projectDataListOptions', tempOptions)
      }
    },

    updateProjectListOptions () {
      const checkProjectDataList = this.mainStateGet('projectData')
      let tempOptions = []
      checkProjectDataList.forEach(function(x) {
        tempOptions.push({ id: x.id, name: x.name })
      })
      this.mainStateSet('projectDataListOptions', tempOptions)
    },

    consoleLog(sType, sMessage, mData) {
      if (this.$env === 'prod') return
      let styles = ''
      switch (sType) {
        case 'info':
          styles = [
            'color: #2ECC71',
          ].join(';');
          break
        case 'state':
          styles = [
            'color: #F312D5FF',
          ].join(';');
          break
        case 'warn':
          styles = [
            'color: #F39C12',
          ].join(';');
          break
        case 'error':
          styles = [
            'color: #FF0000FF',
          ].join(';');
          break
        case 'debug':
          styles = [
            'color: #106eef',
          ].join(';');
          break
        default: // unknown 'type'
          styles = [
            'color: #000',
          ].join(';');
      }
      if (!arguments[2]) { // third argument (data) optional
        console.info('log >> %c[' + sType.toUpperCase() + '] %s', styles, sMessage)
      } else {
        console.info('log >> %c[' + sType.toUpperCase() + '] %s', styles, sMessage, mData)
      }
    },

    getSessionVal(sKey) {
      let returnVal = ''
      if (arguments[1]) { // second argument as true: use decrypt
        returnVal = this.decrypt(sessionStorage.getItem(sKey))
      } else { // second argument not present: no decrypt
        returnVal = sessionStorage.getItem(sKey)
      }
      return returnVal
    },

    setSessionVal(sKey, sValue) {
      if (arguments[2]) { // second argument as true: use encrypt
        sessionStorage.setItem(sKey, this.encrypt(sValue))
      } else { // second argument not present: no encrypt
        sessionStorage.setItem(sKey, sValue)
      }
    },

    encrypt (value) {
      var ls = new SecureLS({ encodingType: 'des', isCompression: false, encryptionSecret: APP_KEY })
      ls.set('secureValue', value)
      const secureValue = localStorage.getItem('secureValue')
      localStorage.removeItem('secureValue')
      return secureValue
    },

    decrypt (value) {
      var ls = new SecureLS({ encodingType: 'des', isCompression: false, encryptionSecret: APP_KEY })
      localStorage.setItem('secureValue', value)
      value = ls.get('secureValue')
      localStorage.removeItem('secureValue')
      return value
    },

  },

}
