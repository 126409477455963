import Vue from "vue";
import Vuex from "vuex";
import modules from "@/state/modules";
import dispatchActionForAllModules from "@/util/dispatch-action-for-all-modules";
import pathify from "vuex-pathify";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

/* create use cookie as persisted state key */
const cookieName = "xid";
if (document.cookie.indexOf(cookieName + "=") === -1) {
  // no cookie, create one
  const domainName = window.location.hostname + ";";
  const secureDomain = domainName.includes("localhost") ? "" : "secure;";
  Vue.prototype.$xId = Math.random().toString(36).substr(2, 10);
  document.cookie =
    cookieName +
    "=" +
    Vue.prototype.$xId +
    "; path=/; " +
    secureDomain +
    " domain=" +
    domainName;
} else {
  // cookie present
  const pattern = RegExp(cookieName + "=.[^;]*");
  const matched = document.cookie.match(pattern);
  Vue.prototype.$xId = matched[0].split("=")[1];
}
// console.log('in store: xId', Vue.prototype.$xId)

pathify.options.deep = 2;
let store = {};
// console.log('non-persisted state')
store = new Vuex.Store({
  modules,
  plugins: [
    pathify.plugin,
    createPersistedState({
      key: Vue.prototype.$xId,
      paths: ["mainStore" /* possible dynamic session id instead */],
      storage: {
        getItem: (key) => localStorage.getItem(key),
        setItem: (key, value) => localStorage.setItem(key, value), // , { expires: 3, secure: true }),
        removeItem: (key) => localStorage.removeItem(key),
      },
    }),
  ],
});
export default store;

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules("init");
