var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        { staticClass: "auth-pages" },
        [
          _c(
            "div",
            {
              staticClass:
                "header-auth position-relative ma-4 pb-16 pt-16 border-radius-xl",
              style: `background-image:  url(${require("../../assets/img/curved-images/curved6.jpg")}; background-size: cover; background-position: 50%;`,
            },
            [
              _c("span", {
                staticClass:
                  "mask bg-gradient-default border-radius-xl opacity-6",
              }),
              this.$route.name == "Pricing"
                ? _c("v-img", {
                    staticClass:
                      "position-absolute opacity-6 start-0 top-0 w-100",
                    attrs: {
                      src: require("@/assets/img/shapes/waves-white.svg"),
                      alt: "pattern-lines",
                    },
                  })
                : _vm._e(),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "d-flex mt-5" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mx-auto py-0 position-relative",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          this.$route.name == "Pricing"
                            ? _c(
                                "h3",
                                {
                                  staticClass:
                                    "text-h3 font-weight-bold text-white text-center mb-2",
                                },
                                [_vm._v(" " + _vm._s(_vm.headerTitle()) + " ")]
                              )
                            : _c(
                                "h1",
                                {
                                  staticClass:
                                    "text-h1 text-white font-weight-bolder text-center mb-2 mt-5",
                                },
                                [_vm._v(" " + _vm._s(_vm.headerTitle()) + " ")]
                              ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-white font-size-root text-center font-weight-thin mb-12",
                            },
                            [_vm._v(" " + _vm._s(_vm.paragraphs) + " ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("app-bar-auth", {
            attrs: {
              background: "transparent",
              "has-bg": "",
              linkColor: "white",
            },
          }),
          _c(
            "fade-transition",
            { attrs: { duration: 200, origin: "center top", mode: "out-in" } },
            [
              _c(
                "v-container",
                { staticClass: "mt-n16 pb-0" },
                [
                  _c("router-view"),
                  !_vm.$route.meta.hideFooter
                    ? _c("content-footer", { attrs: { auth: "" } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }