var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.auth
        ? [
            _c(
              "v-footer",
              {
                staticClass: "mt-10 pt-10 pb-5",
                attrs: { color: "transparent" },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "flex", attrs: { color: "transparent" } },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "px-0" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "mb-2 mx-auto text-center",
                                attrs: { cols: "12", lg: "8" },
                              },
                              _vm._l(_vm.footer, function (item) {
                                return _c(
                                  "a",
                                  {
                                    key: item.linkName,
                                    staticClass:
                                      "text-decoration-none text-secondary ls-0 mx-4 text-md",
                                    attrs: {
                                      href: item.link,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.linkName))]
                                )
                              }),
                              0
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "mx-auto text-center",
                                attrs: { cols: "8" },
                              },
                              _vm._l(_vm.icons, function (item) {
                                return _c(
                                  "v-btn",
                                  {
                                    key: item.icon,
                                    staticClass:
                                      "mx-3 text-secondary no-default-hover",
                                    attrs: {
                                      icon: "",
                                      width: "31",
                                      ripple: false,
                                      link: "",
                                      href: "javascript:;",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { size: "18" } }, [
                                      _vm._v(_vm._s(item.icon)),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "mx-auto text-center",
                                attrs: { cols: "8" },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "copyright text-secondary text-md ls-0",
                                  },
                                  [
                                    _vm._v(
                                      " Copyright © " +
                                        _vm._s(new Date().getFullYear()) +
                                        " Soft by "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "text-decoration-none btn-hover text-primary text-body-2 ls-0",
                                        attrs: { href: "#", target: "_blank" },
                                      },
                                      [_vm._v("SEO Team Flow")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "v-footer",
              { staticClass: "px-6", attrs: { color: "transparent" } },
              [
                _c(
                  "v-card",
                  { staticClass: "flex", attrs: { color: "transparent" } },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "px-0" },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "copyright text-body-2 ls-0 text-muted",
                                },
                                [
                                  _vm._v(
                                    " © " +
                                      _vm._s(new Date().getFullYear()) +
                                      " -- "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-decoration-none text-typo text-body-2 ls-0 font-weight-bold",
                                      attrs: { href: "#", target: "_blank" },
                                    },
                                    [_vm._v(" SEO Team Flow")]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-end",
                                attrs: { cols: "10", md: "6" },
                              },
                              _vm._l(_vm.footer, function (item) {
                                return _c(
                                  "ul",
                                  {
                                    key: item.linkName,
                                    staticClass: "d-flex list-style-none",
                                  },
                                  [
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "text-decoration-none text-muted text-body-2 ls-0 btn-dark-hover no-default-hover",
                                          attrs: {
                                            href: item.link,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.linkName))]
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }