import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
// import ResearchLayout from "../views/Layout/ResearchLayout.vue";
import DashboardLayoutVr from "../views/Layout/DashboardLayoutVr.vue";
import PageLayout from "../views/Layout/PageLayout";
import AuthLoginLayout from "../views/Layout/AuthLoginLayout";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout";

// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const Automotive = () => import("../views/Dashboard/Automotive.vue");
const SmartHome = () => import("../views/Dashboard/SmartHome.vue");
const VrDefault = () => import("../views/Dashboard/VrDefault.vue");
const VrInfo = () => import("../views/Dashboard/VrInfo.vue");
const Crm = () => import("../views/Dashboard/Crm.vue");
const GettingStarted = () => import("../views/Dashboard/GettingStarted.vue");

// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Rtl = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Rtl.vue");
const ProfileOverview = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileOverview.vue"
  );
const Teams = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Teams.vue");
const Projects = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Projects.vue");
const Reports = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Settings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");
const Billing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Invoice = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Invoice.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");

const LoginIndex = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/Login/Index.vue"
    );
const SignUpBasic = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Basic.vue"
  );
const SignUpDefault = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Signup.vue"
  );
const SignUpIllustration = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Illustration.vue"
  );
const SignUpVerify = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Verify.vue"
    );

// Research
const NewProjects = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Research/NewProjects.vue");
const KeywordPlanner = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Research/KeywordPlanner.vue");
const AdConversions = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Research/AdConversions.vue");
const GscQueries = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Research/GscQueries.vue");
const Competitors = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Research/Competitors.vue");

// Configurations
const Kanban = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Configurations/Kanban.vue");
const Wizard = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Configurations/Wizard.vue");
// const Datatables = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Configurations/Clients.vue");
const ClientsDatatable = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Configurations/Clients.vue");
const ProjectsDatatable = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Configurations/Projects.vue");
const Calendar = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Configurations/Calendar.vue");

// Ecommerce
// const NewProduct = () =>
//   import(
//     /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/NewProduct.vue"
//   );
// const EditProduct = () =>
//   import(
//     /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/EditProduct.vue"
//   );
// const ProductPage = () =>
//   import(
//     /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/ProductPage.vue"
//   );
// const OrderList = () =>
//   import(
//     /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderList.vue"
//   );
// const OrderDetails = () =>
//   import(
//     /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderDetails.vue"
//   );

Vue.use(VueRouter);

let vrPages = {
  path: "/",
  component: DashboardLayoutVr,
  name: "Vr",
  children: [
    {
      path: "/pages/dashboards/vr/vr-default",
      name: "VrDefault",
      component: VrDefault,
      meta: {
        groupName: "Dashboards",
      },
    },
    {
      path: "/pages/dashboards/vr/vr-info",
      name: "VrInfo",
      component: VrInfo,
      meta: {
        groupName: "Dashboards",
      },
    },
  ],
};

let profilePages = {
  path: "/",
  component: ProfileLayout,
  name: "Profile",
  children: [
    {
      path: "/pages/pages/profile/overview",
      name: "ProfileOverview",
      component: ProfileOverview,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/teams",
      name: "Teams",
      component: Teams,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/projects",
      name: "Project",
      component: Projects,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let userPages = {
  path: "/",
  component: DashboardLayout,
  name: "Users",
  children: [
    {
      path: "/pages/pages/users/reports",
      name: "Reports",
      component: Reports,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/users/new-user",
      name: "NewUser",
      component: NewUser,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let accountPages = {
  path: "/",
  component: DashboardLayout,
  name: "Account",
  children: [
    {
      path: "/pages/pages/account/settings",
      name: "Settings",
      component: Settings,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/billing",
      name: "Billing",
      component: Billing,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/invoice",
      name: "Invoice",
      component: Invoice,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let projectsPages = {
  path: "/",
  component: DashboardLayout,
  name: "Projects Timeline",
  children: [
    {
      path: "/pages/pages/projects/timeline",
      name: "Timeline",
      component: Timeline,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let researchPages = {
  path: "/",
  component: DashboardLayout,
  name: "Research",
  children: [
    {
      path: "/pages/research/new-projects",
      name: "New Projects",
      component: NewProjects,
      meta: {
        groupName: "Research",
      },
    },
    {
      path: "/pages/research/keyword-planner",
      name: "Keyword Planner",
      component: KeywordPlanner,
      meta: {
        groupName: "Research",
      },
    },
    {
      path: "/pages/research/ad-conversions",
      name: "Ad Conversions",
      component: AdConversions,
      meta: {
        groupName: "Research",
      },
    },
    {
      path: "/pages/research/gsc-queries",
      name: "GSC Queries",
      component: GscQueries,
      meta: {
        groupName: "Research",
      },
    },
    {
      path: "/pages/research/competitors",
      name: "Competitors",
      component: Competitors,
      meta: {
        groupName: "Research",
      },
    },
  ],
};

let configurationPages = {
  path: "/",
  component: DashboardLayout,
  name: "Configurations",
  children: [
    {
      path: "/pages/configurations/kanban",
      name: "Kanban",
      component: Kanban,
      meta: {
        groupName: "Configurations",
      },
    },
    {
      path: "/pages/configurations/wizard",
      name: "Wizard",
      component: Wizard,
      meta: {
        groupName: "Configurations",
      },
    },
    {
      path: "/pages/configurations/clients",
      name: "Clients Data",
      component: ClientsDatatable,
      meta: {
        groupName: "Configurations",
      },
    },
    {
      path: "/pages/configurations/projects",
      name: "Projects Data",
      component: ProjectsDatatable,
      meta: {
        groupName: "Configurations",
      },
    },
    {
      path: "/pages/configurations/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        groupName: "Configurations",
      },
    },
  ],
};

// let ecommercePages = {
//   path: "/",
//   component: ProfileLayout,
//   name: "Ecommerce",
//   children: [
//     {
//       path: "/pages/ecommerce/products/new-product",
//       name: "NewProduct",
//       component: NewProduct,
//       meta: {
//         groupName: "Ecommerce",
//       },
//     },
//   ],
// };

let pricingPage = {
  path: "/",
  component: PageLayout,
  name: "Pricing Page",
  children: [
    {
      path: "/pages/pages/pricing-page",
      name: "Pricing",
      component: Pricing,
    },
  ],
};

let authLoginPage = {
  path: "/",
  component: AuthLoginLayout,
  name: "Authentication Login",
  children: [
    {
      path: "/pages/authentication/login",
      name: "LoginIndex",
      component: LoginIndex,
    },
  ],
};

let authBasicPages = {
  path: "/",
  component: AuthBasicLayout,
  name: "Authentication Basic",
  children: [
    {
      path: "/pages/authentication/signup/basic",
      name: "SignUpBasic",
      component: SignUpBasic,
    },
  ],
};

let authCoverPages = {
  path: "/",
  component: AuthCoverLayout,
  name: "Authentication Signup",
  children: [
    {
      path: "/pages/authentication/signup/signup",
      name: "SignUpDefault",
      component: SignUpDefault,
    },
  ],
};

let authIllustrationPages = {
  path: "/",
  component: AuthIllustrationLayout,
  name: "Authentication Illustration",
  children: [
    {
      path: "/pages/authentication/signup/illustration",
      name: "SignUpIllustration",
      component: SignUpIllustration,
    },
  ],
};

let authVerifyPages = {
  path: "/",
  component: AuthCoverLayout,
  name: "Authentication Verify",
  children: [
    {
      path: "/pages/authentication/signup/verify",
      name: "SignUpVerify",
      component: SignUpVerify,
    },
  ],
};

const routes = [
  {
    path: "/",
    name: "Dashboard",
    redirect: "/pages/dashboards/default",
    component: DashboardLayout,
    children: [
      {
        path: "/pages/dashboards/default",
        name: "Default",
        component: Dashboard,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/automotive",
        name: "Automotive",
        component: Automotive,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/smart-home",
        name: "SmartHome",
        component: SmartHome,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/crm",
        name: "Crm",
        component: Crm,
        meta: {
          groupName: "Components",
          authRequired: true,
        },
      },
      {
        path: "/pages/pages/rtl",
        name: "RTL",
        component: Rtl,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/charts",
        name: "Charts",
        component: Charts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/alerts",
        name: "Alerts",
        component: Alerts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/notifications",
        name: "Notifications",
        component: Notifications,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "getting-started",
        name: "Getting Started",
        component: GettingStarted,
        meta: {
          groupName: "Components",
        },
      },
      // {
      //   path: "/pages/ecommerce/products/edit-product",
      //   name: "EditProduct",
      //   component: EditProduct,
      //   meta: {
      //     groupName: "Ecommerce",
      //   },
      // },
      // {
      //   path: "/pages/ecommerce/products/product-page",
      //   name: "ProductPage",
      //   component: ProductPage,
      //   meta: {
      //     groupName: "Ecommerce",
      //   },
      // },
      // {
      //   path: "/pages/ecommerce/orders/list",
      //   name: "OrderList",
      //   component: OrderList,
      //   meta: {
      //     groupName: "Ecommerce",
      //   },
      // },
      // {
      //   path: "/pages/ecommerce/orders/details",
      //   name: "OrderDetails",
      //   component: OrderDetails,
      //   meta: {
      //     groupName: "Ecommerce",
      //   },
      // },
    ],
  },
  pricingPage,
  profilePages,
  configurationPages,
  researchPages,
  /* ecommercePages, */
  userPages,
  accountPages,
  projectsPages,
  vrPages,
  authLoginPage,
  authBasicPages,
  authCoverPages,
  authIllustrationPages,
  authVerifyPages,
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(r => r.meta.authRequired)) {
    if (localStorage.getItem('authData') === null) {
      next({ 'path': '/pages/authentication/login' })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router;

