var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { staticClass: "px-3 py-5" },
    _vm._l(_vm.pages, function (item, i) {
      return _c(
        "v-list-item",
        {
          key: item.title,
          staticClass: "ps-0",
          class: i < _vm.pages.length - 1 ? "mb-6" : "",
        },
        [
          !item.external
            ? _c(
                "v-btn",
                {
                  staticClass: "text-body text-capitalize ls-0",
                  attrs: {
                    ripple: false,
                    text: "",
                    depressed: "",
                    link: "",
                    to: item.link,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "me-4 mb-4",
                      domProps: { innerHTML: _vm._s(item.icon) },
                    },
                    [_vm._v(" " + _vm._s(item.icon) + " ")]
                  ),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c(
                      "span",
                      { staticClass: "text-dark font-weight-bolder mb-1" },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _c("span", { staticClass: "text-sm texxt-body" }, [
                      _vm._v(_vm._s(item.description)),
                    ]),
                  ]),
                  _c("v-spacer"),
                ],
                1
              )
            : _vm._e(),
          item.external
            ? _c(
                "v-btn",
                {
                  staticClass: "text-body text-capitalize ls-0",
                  attrs: {
                    ripple: false,
                    text: "",
                    depressed: "",
                    link: "",
                    href: item.link,
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "me-4 mb-4",
                      domProps: { innerHTML: _vm._s(item.icon) },
                    },
                    [_vm._v(" " + _vm._s(item.icon) + " ")]
                  ),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c(
                      "span",
                      { staticClass: "text-dark font-weight-bolder mb-1" },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _c("span", { staticClass: "text-sm texxt-body" }, [
                      _vm._v(_vm._s(item.description)),
                    ]),
                  ]),
                  _c("v-spacer"),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }