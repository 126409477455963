var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass:
        "mt-4 position-sticky top-1 px-0 mx-2 border-radius-xl toolbar-content-padding-y-none",
      class: { "border-bottom": !_vm.hasBg },
      attrs: { color: _vm.background, height: "auto", flat: "" },
    },
    [
      _c(
        "v-row",
        { staticClass: "py-1" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "div",
                [
                  _c(
                    "v-breadcrumbs",
                    { staticClass: "pb-0 pt-1 px-0" },
                    [
                      _c(
                        "v-breadcrumbs-item",
                        { staticClass: "opacity-8 text-white" },
                        [_vm._v(" " + _vm._s(_vm.$route.meta.groupName) + " ")]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "v-breadcrumbs__divider text-white px-2",
                        },
                        [_vm._v("/")]
                      ),
                      _c(
                        "v-breadcrumbs-item",
                        {
                          staticClass: "no-default-hover text-white",
                          attrs: { "active-class": "active-breadcrumb" },
                        },
                        [
                          _vm.$route.name === "Dashboard"
                            ? [_vm._v("Default")]
                            : [_vm._v(_vm._s(_vm.$route.name))],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "h6",
                    {
                      staticClass: "text-h6 font-weight-bolder text-white mb-0",
                    },
                    [_vm._v(" " + _vm._s(_vm.$route.name) + " ")]
                  ),
                ],
                1
              ),
              !_vm.$vuetify.breakpoint.mobile
                ? _c(
                    "div",
                    {
                      staticClass: "drawer-toggler pa-5 ms-6 cursor-pointer",
                      class: { active: _vm.togglerActive },
                      on: { click: _vm.minifyDrawer },
                    },
                    [
                      _c("div", { staticClass: "drawer-toggler-inner" }, [
                        _c("i", {
                          staticClass: "drawer-toggler-line bg-white",
                        }),
                        _c("i", {
                          staticClass: "drawer-toggler-line bg-white",
                        }),
                        _c("i", {
                          staticClass: "drawer-toggler-line bg-white",
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { cols: "12", sm: "6" } },
            [
              _vm.hasBg
                ? _c(
                    "v-form",
                    {
                      staticClass:
                        "navbar-search navbar-search-light d-inline-block",
                      attrs: { id: "navbar-search-main" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "v-text-field",
                        {
                          staticClass:
                            "font-size-input placeholder-lighter input-alternative input-icon",
                          attrs: {
                            "rounded-sm": "",
                            "hide-details": "",
                            outlined: "",
                            "background-color": "rgba(255,255,255,.9)",
                            color: "rgba(0,0,0,.6)",
                            light: "",
                            placeholder: "Type here...",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "prepend-inner" },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "rgba(0,0,0,.6)",
                                    size: ".875rem",
                                  },
                                },
                                [_vm._v("fas fa-search")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$vuetify.breakpoint.mobile
                ? _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-600 text-capitalize drawer-toggler py-3 px-6 rounded-sm",
                      class: {
                        "btn-dark-toggler-hover": !_vm.hasBg,
                        "btn-toggler-hover": _vm.hasBg,
                        active: _vm.togglerActive,
                      },
                      attrs: {
                        elevation: "0",
                        ripple: false,
                        height: "43",
                        color: "transparent",
                      },
                      on: { click: _vm.drawerClose },
                    },
                    [
                      _c("div", { staticClass: "drawer-toggler-inner" }, [
                        _c("i", {
                          staticClass: "drawer-toggler-line",
                          class: { "bg-white": _vm.hasBg },
                        }),
                        _c("i", {
                          staticClass: "drawer-toggler-line",
                          class: { "bg-white": _vm.hasBg },
                        }),
                        _c("i", {
                          staticClass: "drawer-toggler-line",
                          class: { "bg-white": _vm.hasBg },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-600 text-white ls-0 text-capitalize pe-0 me-2",
                  attrs: { depressed: "", ripple: false, color: "transparent" },
                },
                [
                  _c("v-icon", { attrs: { size: "16" } }, [
                    _vm._v("fa fa-user me-sm-2 text-sm"),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass:
                        "d-sm-inline font-weight-bold d-none text-white",
                    },
                    [_vm._v("Sign in")]
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-white",
                  attrs: {
                    icon: "",
                    ripple: false,
                    color: _vm.linkColor,
                    small: "",
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "16" } }, [
                    _vm._v("fa fa-cog text-sm"),
                  ]),
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: {
                    transition: "slide-y-transition",
                    "offset-y": "",
                    "offset-x": "",
                    "min-width": "300",
                    "max-width": "300",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "text-white",
                                  attrs: {
                                    icon: "",
                                    ripple: false,
                                    color: _vm.linkColor,
                                    small: "",
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v("fa fa-bell text-sm"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "py-0" },
                    _vm._l(_vm.dropdown, function (item, i) {
                      return _c(
                        "v-list-item",
                        { key: i, staticClass: "pa-4 list-item-hover-active" },
                        [
                          item.avatar
                            ? _c(
                                "v-list-item-avatar",
                                {
                                  staticClass: "my-0 me-4 border-radius-lg",
                                  attrs: { size: 36 },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      alt: `${item.avatar} avatar`,
                                      src: item.avatar,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.image
                            ? _c(
                                "v-list-item-avatar",
                                {
                                  staticClass:
                                    "my-0 me-4 border-radius-lg bg-gradient-default",
                                  attrs: { size: 36 },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      alt: `${item.image} image`,
                                      src: item.image,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.icon
                            ? _c(
                                "v-list-item-avatar",
                                {
                                  staticClass:
                                    "my-0 me-4 border-radius-lg bg-gradient-secondary",
                                  attrs: { size: 36 },
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "pa-2",
                                    attrs: {
                                      alt: `${item.icon} icon`,
                                      src: item.icon,
                                      width: "10",
                                      height: "10",
                                      contain: "",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item-content",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "text-sm font-weight-normal mb-1 text-typo",
                                            domProps: {
                                              innerHTML: _vm._s(item.title),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                { staticClass: "text-xs text-secondary mb-0" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-secondary",
                                      attrs: { size: "12" },
                                    },
                                    [_vm._v("fa fa-clock me-1")]
                                  ),
                                  _vm._v(" " + _vm._s(item.time) + " "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }