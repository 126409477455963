import { make } from "vuex-pathify";
export const state = {
  testString: String(''),
  testBool: Boolean(false),
  jwtLogin: String(''),
  clientIpAddress: String(''),
  clientData: [],
  clientDataListOptions: [],
  projectData: [],
  projectDataListOptions: [],
};

export const getters = {
  ...make.getters(state),
};

export const mutations = {
  ...make.mutations(state),
};

export const actions = {
  ...make.actions(state),
};
