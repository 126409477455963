var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass:
        "mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none",
      class: _vm.navbarFixed
        ? "position-sticky blur shadow-blur top-1 z-index-sticky py-2"
        : "",
      attrs: { color: _vm.background, height: "auto", flat: "" },
    },
    [
      _c(
        "v-row",
        { staticClass: "py-1" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "div",
                [
                  _c(
                    "v-breadcrumbs",
                    { staticClass: "pb-0 pt-1 px-0" },
                    [
                      _c(
                        "v-breadcrumbs-item",
                        {
                          staticClass: "opacity-5 text-dark",
                          attrs: {
                            to: "/pages/dashboards/default",
                            "active-class": "active-breadcrumb",
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "mb-1",
                              attrs: {
                                width: "12px",
                                height: "12px",
                                viewBox: "0 0 45 40",
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              },
                            },
                            [
                              _c("title", [_vm._v("shop")]),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        transform:
                                          "translate(-1716.000000, -439.000000)",
                                        fill: "#252f40",
                                        "fill-rule": "nonzero",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(1716.000000, 291.000000)",
                                          },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(0.000000, 148.000000)",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d: "M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z",
                                                },
                                              }),
                                              _c("path", {
                                                attrs: {
                                                  d: "M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass:
                            "v-breadcrumbs__divider opacity-5 px-2 text-muted",
                        },
                        [_vm._v("/")]
                      ),
                      _c(
                        "v-breadcrumbs-item",
                        { staticClass: "opacity-5 text-dark" },
                        [_vm._v(" " + _vm._s(_vm.$route.meta.groupName) + " ")]
                      ),
                      _c(
                        "li",
                        {
                          staticClass:
                            "v-breadcrumbs__divider opacity-5 px-2 text-muted",
                        },
                        [_vm._v("/")]
                      ),
                      _c(
                        "v-breadcrumbs-item",
                        {
                          staticClass: "no-default-hover text-dark",
                          attrs: { "active-class": "active-breadcrumb" },
                        },
                        [
                          _vm.$route.name === "Dashboard"
                            ? [_vm._v("Default")]
                            : [_vm._v(_vm._s(_vm.$route.name))],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "h6",
                    {
                      staticClass: "text-h6 font-weight-bolder text-typo mb-0",
                    },
                    [_vm._v(" " + _vm._s(_vm.$route.name) + " ")]
                  ),
                ],
                1
              ),
              !_vm.$vuetify.breakpoint.mobile
                ? _c(
                    "div",
                    {
                      staticClass: "drawer-toggler pa-5 ms-6 cursor-pointer",
                      class: { active: _vm.togglerActive },
                      on: { click: _vm.minifyDrawer },
                    },
                    [
                      _c("div", { staticClass: "drawer-toggler-inner" }, [
                        _c("i", { staticClass: "drawer-toggler-line bg-body" }),
                        _c("i", { staticClass: "drawer-toggler-line bg-body" }),
                        _c("i", { staticClass: "drawer-toggler-line bg-body" }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              class: _vm.$vuetify.rtl ? "text-sm-left" : "text-sm-right",
              attrs: { cols: "12", sm: "6" },
            },
            [
              _vm.hasBg
                ? _c(
                    "v-form",
                    {
                      staticClass:
                        "navbar-search navbar-search-light d-inline-block ms-auto",
                      attrs: { id: "navbar-search-main" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "v-text-field",
                        {
                          staticClass:
                            "input-style font-size-input placeholder-lighter input-alternative input-icon border",
                          attrs: {
                            "rounded-sm": "",
                            "hide-details": "",
                            outlined: "",
                            "background-color": "rgba(255,255,255,.9)",
                            color: "rgba(0,0,0,.6)",
                            light: "",
                            placeholder: "Type here...",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "prepend-inner" },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "rgba(0,0,0,.6)",
                                    size: ".875rem",
                                  },
                                },
                                [_vm._v("fas fa-search")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-600 text-body ls-0 text-capitalize pe-0 me-2",
                  attrs: {
                    depressed: "",
                    ripple: false,
                    color: "transparent",
                    to: "/pages/authentication/signup/basic",
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "16" } }, [
                    _vm._v("fa fa-user me-sm-2 text-sm"),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "d-sm-inline font-weight-bold d-none",
                      class: {
                        "btn-dark-hover": !_vm.hasBg,
                        "btn-hover": _vm.hasBg,
                      },
                      style: !_vm.hasBg ? "color: rgba(0,0,0, .6)" : "",
                    },
                    [_vm._v("Sign in")]
                  ),
                ],
                1
              ),
              _vm.$vuetify.breakpoint.mobile
                ? _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-600 text-capitalize drawer-toggler py-3 px-0 rounded-sm",
                      class: {
                        "btn-dark-toggler-hover": !_vm.hasBg,
                        "btn-toggler-hover": _vm.hasBg,
                        active: _vm.togglerActive,
                      },
                      attrs: {
                        elevation: "0",
                        ripple: false,
                        height: "43",
                        color: "transparent",
                      },
                      on: { click: _vm.drawerClose },
                    },
                    [
                      _c("div", { staticClass: "drawer-toggler-inner" }, [
                        _c("i", {
                          staticClass: "drawer-toggler-line text-body",
                        }),
                        _c("i", {
                          staticClass: "drawer-toggler-line text-body",
                        }),
                        _c("i", {
                          staticClass: "drawer-toggler-line text-body",
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "text-body",
                  class: {
                    "btn-dark-hover": !_vm.hasBg,
                    "btn-hover": _vm.hasBg,
                  },
                  attrs: {
                    icon: "",
                    ripple: false,
                    color: _vm.linkColor,
                    small: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggleSettingsDrawer", true)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "16" } }, [
                    _vm._v("fa fa-cog text-sm"),
                  ]),
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: {
                    transition: "slide-y-transition",
                    "offset-y": "",
                    "offset-x": "",
                    "min-width": "300",
                    "max-width": "300",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "text-body",
                                  class: {
                                    "btn-dark-hover": !_vm.hasBg,
                                    "btn-hover": _vm.hasBg,
                                  },
                                  attrs: {
                                    icon: "",
                                    ripple: false,
                                    color: _vm.linkColor,
                                    small: "",
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v("fa fa-bell text-sm"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "py-0" },
                    _vm._l(_vm.dropdown, function (item, i) {
                      return _c(
                        "v-list-item",
                        { key: i, staticClass: "pa-4 list-item-hover-active" },
                        [
                          item.avatar
                            ? _c(
                                "v-list-item-avatar",
                                {
                                  staticClass: "my-0 me-4 border-radius-lg",
                                  attrs: { size: 36 },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      alt: `${item.avatar} avatar`,
                                      src: item.avatar,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.image
                            ? _c(
                                "v-list-item-avatar",
                                {
                                  staticClass:
                                    "my-0 me-4 border-radius-lg bg-gradient-default",
                                  attrs: { size: 36 },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      alt: `${item.image} image`,
                                      src: item.image,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.icon
                            ? _c(
                                "v-list-item-avatar",
                                {
                                  staticClass:
                                    "my-0 me-4 border-radius-lg bg-gradient-secondary",
                                  attrs: { size: 36 },
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "pa-2",
                                    attrs: {
                                      alt: `${item.icon} icon`,
                                      src: item.icon,
                                      width: "10",
                                      height: "10",
                                      contain: "",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item-content",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "text-sm font-weight-normal mb-1 text-typo",
                                            domProps: {
                                              innerHTML: _vm._s(item.title),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                { staticClass: "text-xs text-secondary mb-0" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-secondary",
                                      attrs: { size: "12" },
                                    },
                                    [_vm._v("fa fa-clock me-1")]
                                  ),
                                  _vm._v(" " + _vm._s(item.time) + " "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }