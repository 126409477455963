var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "py-4 px-2 toolbar-content-padding-y-none",
      attrs: { absolute: "", color: _vm.background, height: "auto", flat: "" },
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "3" } },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-weight-bolder ms-lg-0 ms-4 mb-0 text-white text-sm",
                    },
                    [_vm._v(" SEO Team Flow ")]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "mx-auto text-center", attrs: { cols: "6" } },
                [
                  !_vm.$vuetify.breakpoint.mobile
                    ? _vm._l(_vm.links, function (item) {
                        return _c(
                          "v-menu",
                          {
                            key: item.name,
                            attrs: {
                              "open-on-hover": "",
                              "offset-y": "",
                              bottom: "",
                              "min-width": "360",
                              "max-width": "600",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "text-white text-capitalize ls-0 align-center",
                                              class: {
                                                "btn-dark-hover": !_vm.hasBg,
                                                "btn-hover": _vm.hasBg,
                                              },
                                              attrs: {
                                                ripple: false,
                                                color: "transparent",
                                                depressed: "",
                                                link: "",
                                                to: item.link,
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(" " + _vm._s(item.name) + " "),
                                          _c("v-img", {
                                            staticClass:
                                              "arrow ms-1 d-lg-block d-none",
                                            attrs: {
                                              src: require("@/assets/img/down-arrow-white.svg"),
                                              alt: "down-arrow",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              {
                                staticClass: "border-radius-xl overflow-hidden",
                              },
                              [
                                item.name == "Pages"
                                  ? [_c("app-bar-pages")]
                                  : _vm._e(),
                                item.name == "Authentication"
                                  ? [_c("app-bar-authentication")]
                                  : _vm._e(),
                                item.name == "Applications"
                                  ? [_c("app-bar-applications")]
                                  : _vm._e(),
                                item.name == "Ecommerce"
                                  ? [_c("app-bar-ecommerce")]
                                  : _vm._e(),
                                item.name == "Docs"
                                  ? [_c("app-bar-docs")]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      })
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$vuetify.breakpoint.mobile,
                          expression: "$vuetify.breakpoint.mobile",
                        },
                      ],
                      staticClass:
                        "font-weight-600 text-capitalize drawer-toggler btn-toggler-hover py-3 px-6 rounded-sm",
                      attrs: {
                        elevation: "0",
                        ripple: false,
                        height: "43",
                        color: "transparent",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialog = true
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "drawer-toggler-inner" }, [
                        _c("i", {
                          staticClass: "drawer-toggler-line",
                          class: { "bg-white": _vm.hasBg },
                        }),
                        _c("i", {
                          staticClass: "drawer-toggler-line",
                          class: { "bg-white": _vm.hasBg },
                        }),
                        _c("i", {
                          staticClass: "drawer-toggler-line",
                          class: { "bg-white": _vm.hasBg },
                        }),
                      ]),
                    ]
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        "content-class": "position-absolute top-0",
                        width: "95%",
                      },
                      model: {
                        value: _vm.dialog,
                        callback: function ($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "card-shadow card-padding" },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "pt-0 px-4 card-padding text-h6 font-weight-bold text-typo justify-space-between border-bottom",
                            },
                            [
                              _vm._v(" SEO Team Flow "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    width: "31",
                                    ripple: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialog = false
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-typo",
                                      attrs: { size: "18" },
                                    },
                                    [_vm._v("fas fa-times")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "card-padding pb-0 d-flex flex-column px-0",
                            },
                            [
                              _vm._l(_vm.links, function (item) {
                                return _c(
                                  "v-btn",
                                  {
                                    key: item.name,
                                    staticClass:
                                      "text-typo text-capitalize ls-0",
                                    attrs: {
                                      ripple: false,
                                      text: "",
                                      depressed: "",
                                      link: "",
                                      to: item.link,
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                    _c("v-spacer"),
                                  ],
                                  1
                                )
                              }),
                              _c("div", { staticClass: "border-bottom my-7" }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }