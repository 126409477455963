var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "bg-white" },
    [
      _c(
        "v-container",
        { staticClass: "position-sticky top-0 z-index-2 py-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [_c("app-bar-blur-auth")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-main", { staticClass: "auth-pages" }, [
        _c(
          "div",
          {
            staticClass:
              "header-auth position-relative border-radius-xl min-vh-100",
          },
          [
            _c(
              "v-container",
              { staticClass: "py-0" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex flex-column mx-lg-0 mx-auto",
                        attrs: { lg: "4", md: "7" },
                      },
                      [
                        _c(
                          "fade-transition",
                          {
                            attrs: {
                              duration: 200,
                              origin: "center top",
                              mode: "out-in",
                            },
                          },
                          [_c("router-view")],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass:
                          "d-lg-flex d-none h-100 my-auto pe-0 pt-0 position-absolute top-0 end-0 text-center justify-center flex-column",
                        attrs: { cols: "6" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "position-relative bg-gradient-primary h-100 ma-4 px-16 border-radius-lg d-flex flex-column justify-center",
                          },
                          [
                            _c("v-img", {
                              staticClass:
                                "position-absolute opacity-4 start-0 h-100 w-100",
                              attrs: {
                                src: require("@/assets/img/shapes/pattern-lines.svg"),
                                alt: "pattern-lines",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "position-relative" },
                              [
                                _c("v-img", {
                                  staticClass:
                                    "w-100 position-relative z-index-2 mx-auto",
                                  attrs: {
                                    src: require("@/assets/img/illustrations/rocket-white.png"),
                                    alt: "rocket",
                                    "max-width": "500",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "text-h4 mt-10 text-white font-weight-bolder mb-2",
                              },
                              [_vm._v(" Your journey starts here ")]
                            ),
                            _c("p", { staticClass: "text-white" }, [
                              _vm._v(
                                " Just as it takes a company to sustain a product, it takes a community to sustain a protocol. "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }