import axios from 'axios'
import jsCookie from 'js-cookie'
import clientDataFromFile from '../../data/Clients'

export default {
  name: "SEO_TEAM_FLOW-API",

  data() {
    return {
      apiKey: 0,
      jwtAuthToken: "",
      jwtCookieName: "jwtToken",
      baseApiUrl: process.env.VUE_APP_BASE_GATEWAY_URL,
      proxyBasicAuth: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        auth: {
          username: "",
          password: "",
        },
      },
      proxyJwtAuth: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "",
        },
      },
      proxyNoAuth: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
      mockApiClientData: [],
    };
  },
  created () {
  },

  mounted() {
    this.jwtAuthToken = this.mainStateGet("jwtLogin");
    if (this.jwtAuthToken !== '') {
      jsCookie.set(this.jwtCookieName, this.jwtAuthToken, {
        expires: 3,
        secure: true,
      });
    }
    this.apiInit();
  },

  methods: {

    apiInit() {
      this.proxyBasicAuth = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        auth: {
          username: process.env.VUE_APP_BASIC_API_USER,
          password: process.env.VUE_APP_BASIC_API_PASS,
        },
      };
      this.proxyJwtAuth = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.jwtAuthToken,
        },
      };
    },

    async apiSignin (payload) {
      try {
        // this.showLoader()
        const body = {
          ...payload
        };
        // this.showLoader()
        const response = await axios.post(
          this.baseApiUrl + '/signin',
          body,
          this.proxyNoAuth,
        )
        // this.hideLoader()
        return response.data
      } catch (error) {
        return this.processResponseError(error)
      }
    },

    async apiGetProjects () {
      try {
        // this.showLoader()
        const response = await axios.get(
          this.baseApiUrl + '/projects?fields=name&fields=description&fields=target_audience',
          this.proxyNoAuth,
        )
        // this.hideLoader()
        return response.data
      } catch (error) {
        return this.processResponseError(error)
      }
    },

    async apiGetClients () {
      try {
        // this.showLoader()
        const response = await axios.get(
          this.baseApiUrl + '/clients?fields=name&fields=email&fields=phone&fields=status',
          this.proxyNoAuth,
        )
        // this.hideLoader()
        return response.data
      } catch (error) {
        return this.processResponseError(error)
      }
    },

    async apiUpdateClient (payload, id) {
      try {
        // this.showLoader()
        const body = {
          ...payload
        };
        const response = await axios.put(
          this.baseApiUrl + '/clients/' + id,
          body,
          this.proxyNoAuth,
        );
        // this.hideLoader()
        return response.data
      } catch (error) {
        return this.processResponseError(error)
      }
    },

    async apiAddClient (payload) {
      try {
        // this.showLoader()
        const body = {
          ...payload
        };
        const response = await axios.post(
          this.baseApiUrl + '/clients',
          body,
          this.proxyNoAuth,
        );
        // this.hideLoader()
        return response.data
      } catch (error) {
        return this.processResponseError(error)
      }
    },

    async apiDeleteClient (payload) {
      try {
        // this.showLoader()
        const body = {
          ...payload
        };
        const response = await axios.delete(
          this.baseApiUrl + '/clients',
          body,
          this.proxyNoAuth,
        );
        // this.hideLoader()
        return response.data
      } catch (error) {
        return this.processResponseError(error)
      }
    },

    async apiGetClientData() {
      try {
        this.mockApiClientData = clientDataFromFile
        // console.log('this.mockApiClientData', this.mockApiClientData)
        return this.mockApiClientData
      } catch (error) {
        this.consoleLog('error', 'mainApi.js fn(getClientData): error catch', error)
        return this.processResponseError(error);
      }
    },

    async apiGetIpAddress() {
      try {
        // if (this.mainStateGet('clientIpAddress') === '') {
        await fetch('https://api.ipify.org?format=json')
          .then(x => x.json())
          .then(({ ip }) => {
            this.mainStateSet('clientIpAddress', ip)
          })
        // }
      } catch (error) {
        this.consoleLog('error', 'mainApi.js fn(getIpAddress): error catch', error)
        return this.processResponseError(error);
      }
    },

    async accountLogin(appInfo) {
      try {
        this.showLoader();
        const body = {
          userName: appInfo.emailId,
          password: appInfo.password,
          ipAddress: appInfo.ipAddress,
        };
        const response = await axios.post(
          this.baseApiUrl + "/login",
          body,
          this.proxyBasicAuth
        );
        // add token
        this.proxyJwtAuth.headers.Authorization =
          "Bearer " + response.headers.access_token;
        jsCookie.set(this.jwtCookieName, response.headers.access_token, {
          expires: 3,
          secure: true,
        });
        response.data.jwtAuth = response.headers.access_token; // return token to set/use in store sessions
        response.data.jwtRefresh = response.headers.refresh_token; // return token to set/use in store sessions
        response.data.jwtAuthException = response.headers.token_exception; // return token error
        this.hideLoader();
        return response.data;
      } catch (error) {
        this.consoleLog('error', 'mainApi.js fn(accountLogin): error catch', error)
        return this.processResponseError(error);
      }
    },

    getToken() {
      if (!this.proxyJwtAuth.headers.Authorization) {
        const token = jsCookie.get(this.jwtCookieName);
        this.proxyJwtAuth.headers.Authorization = token
          ? "Bearer " + token
          : "";
      }
      return this.proxyJwtAuth;
    },

    processResponseError(error) {
      this.hideLoader();
      axios.defaults.timeout = 0;
      const response = { data: { responseError: true, networkError: false } };
      if (/network/gi.test(error)) response.data.networkError = true;
      return response.data;
    },

    showLoader() {
      window.dispatchEvent(
        new CustomEvent("loader", {
          bubbles: true,
          cancelable: true,
          detail: { value: true },
        })
      );
    },

    hideLoader() {
      window.dispatchEvent(
        new CustomEvent("loader", {
          bubbles: true,
          cancelable: true,
          detail: { value: false },
        })
      );
    },

  },
};
