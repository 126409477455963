var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fixed-plugin",
      class: _vm.showSettingsDrawer == true ? "show" : "",
    },
    [
      _c("v-card", { staticClass: "shadow-lg" }, [
        _c("div", { staticClass: "card-padding mb-16" }, [
          _c("div", { staticClass: "float-start" }, [
            _c(
              "h5",
              { staticClass: "text-h5 text-typo font-weight-bold mt-3 mb-0" },
              [_vm._v(" UI Configurator ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "float-end mt-4" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-dark",
                  attrs: {
                    ripple: false,
                    icon: "",
                    rounded: "",
                    width: "20px",
                    height: "20px",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggleSettingsDrawer", false)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "12" } }, [
                    _vm._v("fa fa-times"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("hr", { staticClass: "horizontal dark my-1" }),
        _c("div", { staticClass: "card-padding" }, [
          _c(
            "div",
            [
              _c(
                "h6",
                { staticClass: "text-h6 text-typo font-weight-bold mb-2" },
                [_vm._v(" Sidebar Colors ")]
              ),
              _c(
                "v-btn-toggle",
                {
                  attrs: {
                    tile: "",
                    color: "accent-3 deep-purple",
                    group: "",
                    defaultValue: "primary",
                    rounded: "",
                  },
                  model: {
                    value: _vm.sidebarColorModel,
                    callback: function ($$v) {
                      _vm.sidebarColorModel = $$v
                    },
                    expression: "sidebarColorModel",
                  },
                },
                [
                  _c("v-btn", {
                    staticClass:
                      "px-0 mx-0 bg-gradient-primary border-radius-lg border-0 me-2",
                    attrs: {
                      elevation: 0,
                      height: "22",
                      width: "22",
                      "min-width": "23",
                      small: "",
                      rounded: "",
                      ripple: false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("updateSidebarColor", "primary")
                      },
                    },
                  }),
                  _c("v-btn", {
                    staticClass:
                      "px-0 mx-0 bg-gradient-default border-radius-lg border-0 me-2",
                    attrs: {
                      elevation: 0,
                      height: "22",
                      width: "22",
                      "min-width": "23",
                      small: "",
                      rounded: "",
                      ripple: false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("updateSidebarColor", "default")
                      },
                    },
                  }),
                  _c("v-btn", {
                    staticClass:
                      "px-0 mx-0 bg-gradient-info border-radius-lg border-0 me-2",
                    attrs: {
                      elevation: 0,
                      height: "22",
                      width: "22",
                      "min-width": "23",
                      small: "",
                      rounded: "",
                      ripple: false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("updateSidebarColor", "info")
                      },
                    },
                  }),
                  _c("v-btn", {
                    staticClass:
                      "px-0 mx-0 bg-gradient-success border-radius-lg border-0 me-2",
                    attrs: {
                      elevation: 0,
                      height: "22",
                      width: "22",
                      "min-width": "23",
                      small: "",
                      rounded: "",
                      ripple: false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("updateSidebarColor", "success")
                      },
                    },
                  }),
                  _c("v-btn", {
                    staticClass:
                      "px-0 mx-0 bg-gradient-warning border-radius-lg border-0 me-2",
                    attrs: {
                      elevation: 0,
                      height: "22",
                      width: "22",
                      "min-width": "23",
                      small: "",
                      rounded: "",
                      ripple: false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("updateSidebarColor", "warning")
                      },
                    },
                  }),
                  _c("v-btn", {
                    staticClass:
                      "px-0 mx-0 bg-gradient-danger border-radius-lg border-0 me-2",
                    attrs: {
                      elevation: 0,
                      height: "22",
                      width: "22",
                      "min-width": "23",
                      small: "",
                      rounded: "",
                      ripple: false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("updateSidebarColor", "danger")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "h6",
            { staticClass: "text-h6 text-typo font-weight-bold mb-0 mt-4" },
            [_vm._v(" Sidenav Type ")]
          ),
          _c("p", { staticClass: "text-sm text-body" }, [
            _vm._v(" Choose between 2 different sidenav types. "),
          ]),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-uppercase btn-default py-2 px-6 me-2",
                  class: !_vm.isActive
                    ? "bg-gradient-primary"
                    : "btn-outline-primary",
                  attrs: {
                    elevation: "0",
                    ripple: false,
                    height: "43",
                    color: "#fff",
                    small: "",
                  },
                  on: {
                    click: function ($event) {
                      ;[
                        _vm.$emit("updateSidebarTheme", "transparent"),
                        _vm.active(),
                      ]
                    },
                  },
                },
                [_vm._v("Transparent")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-uppercase btn-primary btn-outline-primary py-2 px-12 me-2",
                  class: _vm.isActive
                    ? "bg-gradient-primary"
                    : "btn-outline-primary",
                  attrs: {
                    elevation: "0",
                    ripple: false,
                    height: "43",
                    color: "#fff",
                    small: "",
                  },
                  on: {
                    click: function ($event) {
                      ;[_vm.$emit("updateSidebarTheme", "white"), _vm.active()]
                    },
                  },
                },
                [_vm._v("White")]
              ),
            ],
            1
          ),
          _c("p", { staticClass: "text-sm d-lg-none d-block mt-3 text-body" }, [
            _vm._v(" You can change the sidenav type just on desktop view. "),
          ]),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c(
                "h6",
                { staticClass: "text-h6 text-typo font-weight-bold mb-0" },
                [_vm._v("Navbar Fixed")]
              ),
              _c("v-switch", {
                staticClass: "mt-2 ms-1 pt-0 switch",
                attrs: {
                  ripple: false,
                  "hide-details": "",
                  color: "#3a416ff2",
                  inset: "",
                },
                on: {
                  change: function ($event) {
                    return _vm.$emit(
                      "toggleNavbarPosition",
                      _vm.navbarFixedModel
                    )
                  },
                },
                model: {
                  value: _vm.navbarFixedModel,
                  callback: function ($$v) {
                    _vm.navbarFixedModel = $$v
                  },
                  expression: "navbarFixedModel",
                },
              }),
            ],
            1
          ),
          _c("hr", { staticClass: "horizontal dark mb-3 mt-4" }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }