const clients = [
  {
    id: 1,
    name: "Centennial Subaru",
    contact: "Joe Black",
    email: "jblack@centennialsubaru.com",
    phone: "800-555-1212",
    status: "active",
  },
  {
    id: 2,
    name: "Alpine Bank",
    contact: "Joe Black",
    email: "jblack@alpinebank.com",
    phone: "800-555-1212",
    status: "active",
  },
];

export default clients;
