/*
=========================================================
* Vuetify Soft UI Dashboard PRO - v1.0.0
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./state/store";
import DashboardPlugin from "./plugins/dashboard-plugin";

import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
Amplify.configure(config);

// common mixin, common state/store and common api
import mainMx from '@/mixins/mainMixin.js'
Vue.mixin(mainMx)

Vue.config.productionTip = false;
Vue.prototype.$env = process.env.VUE_APP_ENV

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

new Vue({
  router,
  vuetify,
  store,
  watch: {
    '$route': function(to, from) {
      this.consoleLog('info', 'page/route changed: [from, to]', [from, to])
      this.consoleLog('state', 'clientIpAddress: ', this.mainStateGet('clientIpAddress'))
    },
  },
  render: (h) => h(App),
}).$mount("#app");
