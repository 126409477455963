
import { get, call } from 'vuex-pathify'
import store from '@/state/store'

export default {

  data () {
    return {

    }
  },

  computed: {
    ...get('mainStore/*'),
  },

  mounted () {

  },

  watch: {

  },

  methods: {

    ...call('mainStore/*'),

    mainStateSet (name, value) {
      store.set('mainStore/' + name, value)
    },

    mainStateGet (name) {
      return store.get('mainStore/' + name)
    },

  },

}
